import { FC, ReactNode, createContext, useContext, useState } from "react";

interface ProModeContextType {
  promodeEnabled: boolean;
  setPromodeEnabled: (v: boolean) => void;
}

const ProModeContext = createContext<ProModeContextType | undefined>(undefined);

interface ProModeProviderProps {
  children: ReactNode;
}

export const ProModeProvider: FC<ProModeProviderProps> = ({ children }) => {
  const [promodeEnabled, setPromodeEnabled] = useState(false);

  return (
    <ProModeContext.Provider value={{ promodeEnabled, setPromodeEnabled }}>
      {children}
    </ProModeContext.Provider>
  );
};

export const usePromode = () => {
  const context = useContext(ProModeContext);
  if (context === undefined) {
    throw new Error("usePromode must be used within a PromodeProvider");
  }
  return context;
};
