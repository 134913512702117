import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useEffectOnce } from "react-use";

const localStorageKey = "hasSeenPrototypeNotification";

const PrototypeNotification: FunctionComponent = () => {
  useEffectOnce(() => {
    const hasSeenPrototypeNotification = localStorage.getItem(localStorageKey);

    if (!hasSeenPrototypeNotification) {
      onOpen();
    }
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onConfirm = () => {
    localStorage.setItem(localStorageKey, "true");
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => null}
      /* size="2xl" */
      isCentered>
      <ModalOverlay backdropFilter="auto" backdropBlur="8px" />
      <ModalContent p={8}>
        {/* <ModalHeader>Load communication</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        <ModalBody as={VStack} spacing={8}>
          <section style={{ alignItems: "center" }}>
            <Heading fontFamily="corpoA" fontWeight="normal" textAlign="center">
              Synapse
            </Heading>
            <Text fontStyle="italic" textAlign="center">
              Next-generation customer communication
            </Text>

            <Text
              fontSize="xl"
              textAlign="center"
              fontWeight="bold"
              w="full"
              p={4}
              rounded="md">
              <u>This is a protoype</u>
              <br />
              For testing purposes only!
            </Text>

            <Text>
              <b>Important note:</b> Everything sent off will be both stored in
              a database managed by Intuity Media Lab and hosted on Amazon Web
              Services in Europe. Every request is sent to servers by OpenAI
              hosted in the United States of America. Therefore, do not enter
              sensitive or classified data!
            </Text>
          </section>
          <Button size="lg" minW="48" onClick={onConfirm}>
            Confirm
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PrototypeNotification;
