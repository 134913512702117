import type { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "@/theme";
import { Session, SessionContextProvider } from "@supabase/auth-helpers-react";

import "../styles/index.css";
import "../styles/prism.css";

import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { useState } from "react";
import { ProModeProvider } from "@/lib/context/ProModeContext";
import { LoadingProvider } from "@/lib/context/LoadingContext";
import PrototypeNotification from "@/components/PrototypeNotification";

export default function App({
  Component,
  pageProps,
}: AppProps<{
  initialSession: Session;
}>) {
  const [supabaseClient] = useState(() => createPagesBrowserClient());

  return (
    <SessionContextProvider
      supabaseClient={supabaseClient}
      initialSession={pageProps.initialSession}>
      <ChakraProvider theme={theme}>
        <ProModeProvider>
          <LoadingProvider>
            <Component {...pageProps} />
            <PrototypeNotification />
          </LoadingProvider>
        </ProModeProvider>
      </ChakraProvider>
    </SessionContextProvider>
  );
}
