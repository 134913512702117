import { FC, ReactNode, createContext, useContext, useState } from "react";

import { nanoid } from "nanoid";

interface LoadingContextType {
  addToQueue: () => string;
  removeFromQueue: (v: string) => void;
  clearQueue: () => void;
  isLoading: boolean;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

interface LoadingProviderProps {
  children: ReactNode;
}

export const LoadingProvider: FC<LoadingProviderProps> = ({ children }) => {
  /* since in some cases there are several requests happening at the same
  time, we need a queue where each request identifies itself via a uuid
  (see useAiData hook for this) */

  const [queue, setQueue] = useState<string[]>([]);

  const addToQueue = () => {
    const id = nanoid();
    setQueue(state => [...state, id]);
    return id;
  };

  const removeFromQueue = (v: string) => {
    /* the useAiData removes itself from the queue once the request is finished
    no matter if it was successful or an error has occured */
    setQueue(state => {
      const newState = [...state];
      const index = state.indexOf(v, 0);
      if (index > -1) {
        newState.splice(index, 1);
      }
      return newState;
    });
  };

  const clearQueue = () => {
    /* the queue is cleared on first render in the layout component to
    get rid of ongoing request from the previous route */
    setQueue([]);
  };

  /* if there is something in the queue it is loading */
  const isLoading = !!queue.length;

  return (
    <LoadingContext.Provider
      value={{ isLoading, addToQueue, clearQueue, removeFromQueue }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useIsLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error(
      "useIsLoading must be used within a LoadingContextProvider",
    );
  }
  return context;
};
